<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      :class="{ 'd-lg-none': !!show, 'ml-3': true }"
      @click="toggleShowHide"
    />
    <CHeaderBrand :class="{ 'd-lg-none': !!show, 'mx-auto': true }" to="/">
      <img v-bind:src="logo" alt="logo" class="menu_logo" />
    </CHeaderBrand>
    <CHeaderNav :class="{ 'd-md-down-none': true, 'mr-auto': show }">
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <div class="dropdown-header-api">
        <ApiUrlChange />
      </div>

      <CDropdown
        class="dropdown-header-adm"
        placement="bottom-start"
        color="primary"
        variant="btn-group"
        :togglerText="getSubstring(colaborador.nome)"
      >
        <CDropdownItem @click="logout"> Deslogar </CDropdownItem>
      </CDropdown>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import config from "../../config";
import ApiUrlChange from "../ApiUrlChange";

export default {
  name: "TheHeader",
  components: {
    ApiUrlChange,
  },
  props: ["show", "toggleShowHide"],
  data() {
    return {
      logo: config.api + "/images/logo",
      loading: false,
      colaborador: {
        nome: "",
      },
    };
  },
  methods: {
    logout() {
      const persist_api_url = localStorage.getItem("API_URL");
      const persist_cdn_url = localStorage.getItem("CDN_URL");
      const persist_ws_host = localStorage.getItem("WS_HOST");
      const persist_ws_key = localStorage.getItem("WS_KEY");
      const persist_ws_port = localStorage.getItem("WS_PORT");

      localStorage.clear();

      if (persist_api_url) {
        localStorage.setItem("API_URL", persist_api_url);
      }

      if (persist_cdn_url) {
        localStorage.setItem("CDN_URL", persist_cdn_url);
      }

      if (persist_ws_host) {
        localStorage.setItem("WS_HOST", persist_ws_host);
      }

      if (persist_ws_key) {
        localStorage.setItem("WS_KEY", persist_ws_key);
      }

      if (persist_ws_port) {
        localStorage.setItem("WS_PORT", persist_ws_port);
      }

      this.loading = false;
      this.$router.push("/login");
    },
    getSubstring(nome) {
      return nome.length > 20 ? `${nome.substring(0, 20)} ...` : nome;
    },
  },
  beforeMount() {
    const { colaborador } = JSON.parse(localStorage.getItem("app"));
    this.colaborador = colaborador;
  },
};
</script>
